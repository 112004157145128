.order-details {
  @apply w-full p-6;
  background-color: #F5F6F6;

  &__item {
    @apply my-2 flex justify-between;

    &__title {
      @apply font-medium;
    }

    &__description {
      @apply text-right;

    }
  }
}
